.isAP2NotEnabled .style1.quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: Rota;
  font-weight: 600;
  color: #80829d;
}

.isAP2NotEnabled .text-editor {
  margin-bottom: 20px;
}

.isAP2NotEnabled .ql-editor {
  font-family: sans-serif;
}
.isAP2NotEnabled .ql-editor p {
  font-family: sans-serif !important;
  font-weight: normal !important;
}

.isAP2NotEnabled .ql-editor p strong {
  font-weight: 700 !important;
}

.isAP2NotEnabled .ql-toolbar .ql-stroke {
  fill: none;
  stroke: #f5f6fb;
}

.isAP2NotEnabled .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background-color: #262949;
  border: none;
  color: white;
  border-radius: 4px;
}

.isAP2NotEnabled .ql-container.ql-snow {
  border: none;
  background-color: #191b36;
  border-radius: 4px;
  font-family: Rota;
  font-size: 16px;
  min-height: 130px;
  overflow-wrap: anywhere !important;
}

.isAP2NotEnabled .ql-toolbar.ql-snow {
  border: none !important;
  padding-bottom: 0;
  color: #f5f6fb;
}

.isAP2NotEnabled .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 16px;
  color: #80829d;
}

.isAP2NotEnabled .ql-snow .ql-picker.ql-font,
.isAP2NotEnabled .ql-snow .ql-picker.ql-size,
.isAP2NotEnabled .ql-snow.ql-toolbar .ql-italic,
.isAP2NotEnabled .ql-snow.ql-toolbar .ql-color,
.isAP2NotEnabled .ql-snow.ql-toolbar .ql-underline {
  border-right: 1px solid #b6b8d5;
  padding-right: 8px;
  margin-right: 8px;
}

.isAP2NotEnabled .ql-snow.ql-toolbar .ql-image {
  border-left: 1px solid #b6b8d5;
  padding-left: 13px;
  margin-right: 13px;
  margin-left: 8px;
}

.isAP2NotEnabled .ql-snow.ql-toolbar .ql-color {
  padding-right: 8px;
  padding-left: 8px;
  width: 48px;
}

.isAP2NotEnabled .ql-snow.ql-toolbar .ql-italic {
  margin-right: 8px;
  width: 36px;
}

.isAP2NotEnabled .ql-snow .ql-picker.ql-font {
  width: 140px;
}

.isAP2NotEnabled .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: transparent;
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='sans']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='sans']::before {
  content: 'Sans Serif';
  font-family: sans-serif;
}

.isAP2NotEnabled .ql-font-sans {
  font-family: sans-serif;
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='serif']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
  font-family: 'Serif';
}

.isAP2NotEnabled .ql-font-serif {
  font-family: 'Serif';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='fixed']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='fixed']::before {
  content: 'Fixed Width';
  font-family: 'Courier';
}

.isAP2NotEnabled .ql-font-fixed {
  font-family: 'Courier';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='narrow']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='narrow']::before {
  content: 'Narrow';
  font-family: 'Arial Narrow';
}

.isAP2NotEnabled .ql-font-narrow {
  font-family: 'Arial Narrow';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='wide']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='wide']::before {
  content: 'Wide';
  font-family: 'Arial Black';
}

.isAP2NotEnabled .ql-font-wide {
  font-family: 'Arial Black';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='comic']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='comic']::before {
  content: 'Comic Sans MS';
  font-family: 'Comic Sans MS';
}

.isAP2NotEnabled .ql-font-comic {
  font-family: 'Comic Sans MS';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='garamond']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='garamond']::before {
  content: 'Garamond';
  font-family: 'Garamond';
}

.isAP2NotEnabled .ql-font-garamond {
  font-family: 'Garamond';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='georgia']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='georgia']::before {
  content: 'Georgia';
  font-family: 'Georgia';
}

.isAP2NotEnabled .ql-font-georgia {
  font-family: 'Georgia';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='tahoma']::before,
.isAP2NotEnabled
  .isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='tahoma']::before {
  content: 'Tahoma';
  font-family: 'Tahoma';
}

.isAP2NotEnabled .ql-font-tahoma {
  font-family: 'Tahoma';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='trebuchet']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='trebuchet']::before {
  content: 'Trebuchet MS';
  font-family: 'Trebuchet MS';
}

.isAP2NotEnabled .ql-font-trebuchet {
  font-family: 'Trebuchet MS';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='verdana']::before,
.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='verdana']::before {
  content: 'Verdana';
  font-family: 'Verdana';
}

.isAP2NotEnabled .ql-font-verdana {
  font-family: 'Verdana';
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='small']::before {
  content: 'Small';
  font-size: 12px !important;
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='medium']::before {
  content: 'Medium';
  font-size: 16px !important;
}

.isAP2NotEnabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='large']::before {
  content: 'Large';
  font-size: 22px !important;
}

.isAP2NotEnabled .ql-snow .ql-tooltip.ql-flip {
  display: none;
}

.isAP2NotEnabled .ql-buttonlink {
  color: white !important;
  border-radius: 4px;
  background-color: #262949;
  height: 40px;
  width: 100px;
  text-decoration: none !important;
  padding: 10px 20px;
}

.isAP2NotEnabled #tab-panel {
  background-color: #383b63 !important;
}
.isAP2NotEnabled #tab-toolbar {
  background-color: #383b63 !important;
}

/* isAP2Enabled */
.isAP2Enabled .quill > .ql-container > .ql-editor.ql-blank::before {
  font-family: Rota;
  font-weight: 600;
  color: #80829d;
}

.isAP2Enabled .text-editor {
  margin-bottom: 20px;
}

.isAP2Enabled .ql-editor {
  font-family: Rota;
  overflow-wrap: anywhere;
}
.isAP2Enabled .ql-editor p {
  font-family: Rota !important;
  font-weight: normal !important;
  color: #262949;
}

.isAP2Enabled .ql-editor p strong {
  font-family: Rota !important;
  font-weight: 800 !important;
}

.isAP2Enabled .ql-editor link {
  font-family: Rota !important;
  font-weight: 600;
  color: #6d79ff;
}

.isAP2Enabled .ql-openEditInsertLink svg > path,
.isAP2Enabled .ql-emoji svg > path,
.isAP2Enabled #email-toolbar button svg > path {
  fill: #262949;
}

.isAP2Enabled #email-toolbar.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #262949;
}

.isAP2Enabled #slack-toolbar button svg > path {
  fill: #262949;
}

.isAP2Enabled #slack-toolbar.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #262949;
}

.isAP2Enabled .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  background-color: #262949;
  border: none;
  color: white;
  border-radius: 4px;
}

.isViewDisabled .ql-container.ql-snow {
  border: 2px solid #e8ebf5 !important;
  background-color: #ffff !important;
  border-radius: 4px;
  font-family: Rota;
  font-size: 16px;
  min-height: 130px;
}

.isAP2Enabled .ql-container.ql-snow {
  border: none;
  background-color: #f5f6fb;
  border-radius: 4px;
  font-family: Rota;
  font-size: 16px;
  min-height: 130px;
}

.isAP2Enabled .ql-toolbar.ql-snow {
  border: none !important;
  padding-bottom: 0;
  color: #f5f6fb;
}

.isAP2Enabled .ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 16px;
  color: #80829d;
}

.isAP2Enabled .ql-snow .ql-picker.ql-font,
.isAP2Enabled .ql-snow .ql-picker.ql-size,
.isAP2Enabled .ql-snow.ql-toolbar .ql-italic,
.isAP2Enabled .ql-snow.ql-toolbar .ql-color,
.isAP2Enabled .ql-snow.ql-toolbar .ql-underline {
  border-right: 1px solid #b6b8d5;
  padding-right: 8px;
  margin-right: 8px;
}

.isAP2Enabled .ql-snow.ql-toolbar .ql-image {
  border-left: 1px solid #b6b8d5;
  padding-left: 13px;
  margin-right: 13px;
  margin-left: 8px;
}

.isAP2Enabled .ql-snow.ql-toolbar .ql-color {
  padding-right: 8px;
  padding-left: 8px;
  width: 48px;
}

.isAP2Enabled .ql-snow.ql-toolbar .ql-italic {
  margin-right: 8px;
  width: 36px;
}

.isAP2Enabled .ql-snow .ql-picker.ql-font {
  width: 140px;
}

.isAP2Enabled .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: transparent;
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='sans']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='sans']::before {
  content: 'Sans Serif';
  font-family: sans-serif;
}

.isAP2Enabled .ql-font-sans {
  font-family: sans-serif;
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='serif']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='serif']::before {
  content: 'Serif';
  font-family: 'Serif';
}

.isAP2Enabled .ql-font-serif {
  font-family: 'Serif';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='fixed']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='fixed']::before {
  content: 'Fixed Width';
  font-family: 'Courier';
}

.isAP2Enabled .ql-font-fixed {
  font-family: 'Courier';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='narrow']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='narrow']::before {
  content: 'Narrow';
  font-family: 'Arial Narrow';
}

.isAP2Enabled .ql-font-narrow {
  font-family: 'Arial Narrow';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='wide']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='wide']::before {
  content: 'Wide';
  font-family: 'Arial Black';
}

.isAP2Enabled .ql-font-wide {
  font-family: 'Arial Black';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='comic']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='comic']::before {
  content: 'Comic Sans MS';
  font-family: 'Comic Sans MS';
}

.isAP2Enabled .ql-font-comic {
  font-family: 'Comic Sans MS';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='garamond']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='garamond']::before {
  content: 'Garamond';
  font-family: 'Garamond';
}

.isAP2Enabled .ql-font-garamond {
  font-family: 'Garamond';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='georgia']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='georgia']::before {
  content: 'Georgia';
  font-family: 'Georgia';
}

.isAP2Enabled .ql-font-georgia {
  font-family: 'Georgia';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='tahoma']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='tahoma']::before {
  content: 'Tahoma';
  font-family: 'Tahoma';
}

.isAP2Enabled .ql-font-tahoma {
  font-family: 'Tahoma';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='trebuchet']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='trebuchet']::before {
  content: 'Trebuchet MS';
  font-family: 'Trebuchet MS';
}

.isAP2Enabled .ql-font-trebuchet {
  font-family: 'Trebuchet MS';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value='verdana']::before,
.isAP2Enabled
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value='verdana']::before {
  content: 'Verdana';
  font-family: 'Verdana';
}

.isAP2Enabled .ql-font-verdana {
  font-family: 'Verdana';
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='small']::before {
  content: 'Small';
  font-size: 12px !important;
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='medium']::before {
  content: 'Medium';
  font-size: 16px !important;
}

.isAP2Enabled
  .ql-snow
  .ql-picker.ql-size
  .ql-picker-item[data-value='large']::before {
  content: 'Large';
  font-size: 22px !important;
}

.isAP2Enabled .ql-snow .ql-tooltip.ql-flip {
  display: none;
}

.isAP2Enabled .ql-buttonlink {
  color: white !important;
  border-radius: 4px;
  background-color: #262949;
  height: 40px;
  width: 100px;
  text-decoration: none !important;
  padding: 10px 20px;
}

.isAP2Enabled #tab-panel {
  background-color: #383b63 !important;
}
.isAP2Enabled #tab-toolbar {
  background-color: #383b63 !important;
}

.ql-emojiblot {
  vertical-align: middle;
}
