
.custom-scrollbar::-webkit-scrollbar {
    width: 4px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #B6B8D5; 
    border-radius: 30px; 
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: white; 
  }
  

  